export default {
  'rome-condotti': [
    {
      title: 'SERPENTI TUBOGAS WATCH',
      price: '€ 9.400,00',
      image: require('@/assets/img/product/serpenti-tubogas-orologio-103524.png').default,
      link: 'https://www.bulgari.com/en-int/103524.html'
    },
    {
      title: 'SERPENTI TUBOGAS WATCH',
      price: '€ 11.800,00',
      image: require('@/assets/img/product/serpenti-tubogas-orologio-103433.png').default,
      link: 'https://www.bulgari.com/en-int/103433.html'
    },
    {
      title: 'SERPENTI TUBOGAS WATCH',
      price: '€ 5.950,00',
      image: require('@/assets/img/product/serpenti-tubogas-orologio-102824.png').default,
      link: 'https://www.bulgari.com/en-int/102824.html'
    },
    {
      title: 'BVLGARI ALUMINIUM WATCH',
      price: '€ 2.950,00',
      image: require('@/assets/img/product/bvlgari-alumunium-orologio-103382.png').default,
      link: 'https://www.bulgari.com/en-int/103382.html'
    },
    {
      title: 'BVLGARI ALUMINIUM WATCH',
      price: '€ 2.950,00',
      image: require('@/assets/img/product/bvlgari-alumunium-orologio-103445.png').default,
      link: 'https://www.bulgari.com/en-int/103445.html'
    }, {
      title: 'BVLGARI ALUMINIUM WATCH',
      price: '€ 4.250,00',
      image: require('@/assets/img/product/bvlgari-alumunium-orologio-103383.png').default,
      link: 'https://www.bulgari.com/en-int/103383.html'
    }
  ]
}
