<template>
  <div class="shop mt-12" :class="{'landscape': viewPort.width > viewPort.height}">
    <div v-if="collection" class="product-container" v-drag="onDrag" @mouseup="finishDrag">
      <div class="scroller" :style="{'transform' : 'translateX( ' + galleryX + 'px )'}">
        <div :class="{'active' : currentImage === index}"
          class="product align-center"
          :ref="'product'+index"
          v-for="(product, index) in collection"
          :key="product.title + index">
          <transition name="slide">
            <h4 v-if="currentImage === index">{{product.title}}</h4>
          </transition>
          <transition name="slide">
            <p v-if="currentImage === index">{{product.price}}</p>
          </transition>
          <v-img
            draggable="false"
            @mousedown="onImageDown"
            class="img"
            :src="product.image"></v-img>
        </div>
      </div>
      <h5 class="button" @click="visitStore">View on website</h5>
    </div>
  </div>
</template>

<script>
import data from '@/assets/data/product.js'
import Viewport from '@monogrid/vue-lib/lib/mixins/Viewport'

export default {
  mixins: [Viewport],
  data () {
    return {
      data,
      galleryX: 0,
      currentImage: 0,
      collection: null
    }
  },

  components: {
  },

  mounted () {
    this.checkRoute()

    this.isSwiping = false
    this.isChangingGallery = false
    this.firstTouchX = 0

    document.addEventListener('touchstart', (e) => {
      this.isSwiping = true
      this.firstTouchX = e.touches[0].clientX
    }, false)

    document.addEventListener('touchmove', (e) => {
      this.isSwiping = false
      if (e.touches[0].clientX - this.firstTouchX > 30 && !this.isChangingGallery) {
        this.currentImage = Math.max(0, this.currentImage - 1)
        this.isChangingGallery = true
        this.updateGalleryX()
      }

      if (e.touches[0].clientX - this.firstTouchX < -30 && !this.isChangingGallery) {
        this.currentImage = Math.min(this.collection.length - 1, this.currentImage + 1)
        this.isChangingGallery = true
        this.updateGalleryX()
      }
    }, false)

    document.addEventListener('touchend', (e) => {
      this.isSwiping = false
      this.isChangingGallery = false
    }, false)
  },

  methods: {
    onDecode (decodedString) {
      window.open(decodedString, '_self')
    },

    visitStore () {
      if (this.$gtag) {
        this.$gtag.event('visit_store', { product: this.collection[this.currentImage].link })
        console.log('track visit_store', this.collection[this.currentImage].link)
      }
      window.open(this.collection[this.currentImage].link, '_blank')
    },
    updateGalleryX () {
      const w = this.$refs.product0[0].clientWidth
      console.log(w)
      this.galleryX = this.currentImage * w * -1
    },
    onImageDown (e) {
      // console.log(e.clientX)
      this.startMouseX = e.clientX
    },
    finishDrag () {
      this.isSwiping = false
      this.isChangingGallery = false
    },
    onDrag ({ deltaX, startX, startY }) {
      // console.log(deltaX, startX, startY)

      if (!this.isChangingGallery && deltaX !== 0) {
        if (deltaX < 0) {
          this.currentImage = this.currentImage < this.collection.length - 1 ? this.currentImage + 1 : this.collection.length - 1
        } else {
          this.currentImage = this.currentImage > 0 ? this.currentImage - 1 : 0
        }
        this.updateGalleryX()
        this.isChangingGallery = true
      }
    },
    checkRoute () {
      if (this.$route.params.id) {
        console.log(this.$route.params.id)
        if (this.data[this.$route.params.id]) {
          this.collection = this.data[this.$route.params.id]
        } else {
          this.$router.push({ name: 'Home' })
        }
      } else {
        this.$router.push({ name: 'Home' })
      }
    }
  },

  watch: {
    $route () {
      this.checkRoute()
    },

    viewPort () {
      this.updateGalleryX()
    }
  }
}
</script>

<style lang="scss" scoped>
.shop {
  text-align: center;
  max-width: 100vw;
  overflow: hidden;
}

.scroller {
  white-space: nowrap;
  width: auto;
  transition: transform 0.5s;
  margin-left: 8vw;
}

.landscape {
  .scroller {
    margin-left: calc((100vw - 80vh) / 2);
  }

  .product {
    width: 80vh;
  }

  .qrcode {
    width: 80vh;
    display: inline-block;
    margin: auto;
  }
}

.product {
  display: inline-block;
  width: 84vw;
  padding: 5px;
  white-space: normal;

  ::v-deep .v-image__image {
    opacity: 0.2;
    transition: opacity 1s 0.25s;
  }

  &.active {
    ::v-deep .v-image__image {
      opacity: 1;
    }
  }
}

.img {
  background-color: #efede7;
}

.button {
  font-style: italic;
  margin: 12px auto;
  pointer-events: pointer;
  padding: 10px;
  border: 1px solid $c-black;
  display: inline-block;
}
</style>
