<template>
  <v-container class="home-page">
    <qrcode-stream class="mt-12" @decode="onDecode"></qrcode-stream>
  </v-container>
</template>

<script>
import Viewport from '@monogrid/vue-lib/lib/mixins/Viewport'
import { QrcodeStream } from 'vue-qrcode-reader'

export default {
  name: 'Home',

  components: { QrcodeStream },

  mixins: [Viewport],

  data () {
    return {

    }
  },

  computed: {
  },

  watch: {
  },
  methods: {
    onDecode (decodedString) {
      console.log(decodedString)
    }
  },

  metaInfo () {
    return {
      // home page browser title
      title: this.$gettext('Home Page')
    }
  }
}
</script>

<style scoped lang="scss">

</style>
